.wrapper {
  background: linear-gradient(107.97deg, #B6C4CD 22.01%, #91A1AA 50.06%, #5C6A73 107.22%);
  display: flex;
  align-items: center;
  width: fit-content;
}

.wrapper > span {
  text-transform: uppercase;
  font-weight: bold !important;
  color: #FFFFFF;
}

.wrapper.wrapper_middle {
  border-radius: 11px;
  padding: 2px 14px;
}

.wrapper.wrapper_small {
  border-radius: 7px;
  padding: 1px 6px;
}

.wrapper.wrapper_small.wrapper_indent {
  margin-right: 5px;
}

.wrapper.wrapper_small > img {
  width: 12px;
  height: 12px;
  margin-right: 3px;
}

.wrapper.wrapper_small > span {
  font-size: 14px;
}

.wrapper.wrapper_middle > img {
  width: 23px;
  height: 23px;
  margin-right: 3px;
}

.wrapper.wrapper_middle > span {
  font-size: 23px;
}
